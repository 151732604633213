import {Controller} from 'stimulus';
import {MobileAppHelper} from '../helpers/mobile_app_helper';

export default class extends Controller {
  static targets = ['cart', 'itemCount', 'itemAmount', 'shipping', 'shippingExcluded',
    'shippingFree', 'shippingAmount', 'discount', 'discountAmount', 'totalAmount',
    'tokenField', 'minimumOrderAmount', 'minimumOrderButton', 'orderButton',
    'totalAmountBlock', 'quantity', 'itemId'];

  static oldQuantityValue;
  static values = {
    summaryPartialPath: String,
  }

  connect() {
    fetch('/csrf_token').then((res) => res.text()).then((token) => {
      this.tokenFieldTarget.value = token;

      $.ajax({
        url: this.data.get('summary-url'), type: 'get',
        data: {authenticity_token: this.tokenFieldTarget.value, r: new Date().getTime()}},
      ).done((result) => {
        this.updateShopItems(result.summary);
      });
    });

    if (this.data.get('main') == 'true') {
      window.addEventListener('add-to-cart', (e) => {
        this.addToCart(e);
      });
    }

    if (this.hasQuantityTarget) {
      this.oldQuantityValue = this.quantityTarget.value;
    }
  }

  addToCart(e) {
    e.preventDefault();

    fetch('/csrf_token').then((res) => res.text()).then((token) => {
      this.tokenFieldTarget.value = token;
      let quantity = 1;
      if (this.hasQuantityTarget) {
        quantity = this.quantityTarget.value - this.oldQuantityValue;
      }

      if (quantity == 0) {
        return;
      }

      let url;
      if (this.hasQuantityTarget) {
        url = this.quantityTarget.closest('form').action;
      } else {
        url = e.target.closest('form').action;
      }

      $.ajax( {
        url: url,
        type: 'post',
        data: {
          authenticity_token: this.tokenFieldTarget.value,
          quantity: quantity,
        },
      }).done((result) => {
        if (result.error) {
          showToast(result.error, 'alert alert-danger alert-cart-add');
          return;
        }

        if (!this.data.get('no-summary')) {
          this.updateCart(result.summary);
        }
        this.updateCartDropdown(result.summary);
        this.updateShopItems(result.summary);

        let message = this.data.get('message-item-added');

        if (this.hasQuantityTarget) {
          this.oldQuantityValue = this.quantityTarget.value;
          if (this.oldQuantityValue == 0) {
            message = this.data.get('message-item-removed');
          }
        }

        let itemContainer;
        if (typeof e.target.closest === 'function') {
          itemContainer = e.target.closest('.' + (this.data.get('item-class') || 'card'));
        }
        let title = undefined;
        if (this.data.get('item-title')) {
          title = this.data.get('item-title');
        } else if (itemContainer) {
          title = itemContainer.querySelector('.title').innerHTML;
        }
        if (title) {
          message = message.replace('%{title}', title);
        }

        if (this.data.get('cart-url')) {
          message = message.replace('%{cart_url}', this.data.get('cart-url'));
        } else {
          const linkElement = itemContainer.querySelector('.user-name span.link');
          if (linkElement) {
            const shopLink = linkElement.innerHTML;
            message = message.replace('%{shop}', shopLink);
          }
        }
        showToast(message, 'alert alert-success alert-cart-add');
      });
    });
  }

  updateCart(summary) {
    fetch(this.summaryPartialPathValue)
        .then((response) => response.text())
        .then((html) => this.cartTarget.innerHTML = html);
  }

  updateShopItems(summary) {
    const btnIdPrefix = this.data.get('add-btn-id-prefix') || 'add_to_cart_';
    summary.collected_item_ids.forEach((id) => {
      const btn = document.getElementById(btnIdPrefix+id);
      if (btn) {
        btn.classList.add('added');
      }
    });

    if (this.hasItemIdTarget) {
      if (!summary.collected_item_ids.includes(parseInt(this.itemIdTarget.value))) {
        const btn = document.getElementById(btnIdPrefix+this.itemIdTarget.value);
        if (btn) {
          btn.classList.remove('added');
        }
      }
      this.updateButtonStyling(document.getElementById(btnIdPrefix+this.itemIdTarget.value).classList);
    }
  }

  updateButtonStyling(classList) {
    window.dispatchEvent(
        new CustomEvent('add-to-cart-button-updated',
            {
              detail: {
                classList: classList,
              },
            },
        ),
    );
  }

  updateCartDropdown(summary) {
    const cartDropdown = document.getElementById('shopping_cart_dropdown_count');
    if (cartDropdown) {
      cartDropdown.innerHTML = summary.total_quantity;
    }
    MobileAppHelper.updateShoppingCartCount(summary.total_quantity);
  }
}
