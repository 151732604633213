let Trix  = require("trix")
require("@rails/actiontext")

// Changing the TRIX editor to contain new buttons and tooltips
addEventListener("trix-initialize", function(event) {

  let linkHTML = '<button class="trix-button trix-button--icon trix-button--icon-ld-link" type="button" data-trix-action="x-link" data-trix-attribute="ld-link">#</button>'
  let mentionHTML= '<button class="trix-button trix-button--icon trix-button--icon-ld-mention" type="button" data-trix-action="x-mention" data-trix-attribute="ld-mention">@</button>'
  event.target.toolbarElement.
  querySelector(".trix-button-group--file-tools").
  insertAdjacentHTML("beforeend", linkHTML)

  event.target.toolbarElement.
  querySelector(".trix-button-group--file-tools").
  insertAdjacentHTML("beforeend", mentionHTML)

  let buttons = ["bold", "italic", "href", "quote", "number", "bullet", "ld-link", "ld-mention"]
  let nl_translations = ["Dikgedrukt","Schuingedrukt", "Link een url", "Quote", "Een lijst met nummers", 
                      "Een lijst met punten", "Maak een link naar een LastDodo catalogusnummer. Bijvoorbeeld #123456", "Attendeer een gebruiker op dit bericht. Bijvoorbeeld @John"];

  let en_translations = ["Bold", "Italic", "Link a url", "Quote", "List with numbers",
                      "List with bullets", "Link a LastDodo catalog number, for example #123456", "Notify a user about this message, for example @John"]

  let translations;
  const host = window.location.hostname;

  if (host.endsWith('.nl')) {
    translations = nl_translations
  } else {
    translations = en_translations
  }
  let button;
  for(button in buttons) {
    document.querySelector('button[data-trix-attribute="'+ buttons[button] + '"]').setAttribute('title', translations[button]);
  }
  if (host.endsWith('.nl')) {
    document.querySelector('button[data-trix-action="attachFiles"').setAttribute('title', "Voeg een bestand toe")
  }
  else {
    document.querySelector('button[data-trix-action="attachFiles"').setAttribute('title', "Attach a file")
  }
})

addEventListener("trix-action-invoke", function(event) {
  let element = document.querySelector("trix-editor")
  let editor = element.editor  // is a Trix.Editor instance
  if(event.actionName === "x-link"){
    editor.insertString("#")
  }
  else if (event.actionName === "x-mention"){
    window.dispatchEvent(new CustomEvent("show-mention"))
  }
})