import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['compact', 'all'];

  expand() {
    this.compactTarget.hidden=true;
    this.allTarget.hidden=false;
  }
}
