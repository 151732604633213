import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['labelFormat', 'labelPosition', 'previewButton']

  connect() {
    this.apiKey = this.data.get('adobe-api-key');
    this.baseURL = this.data.get('base-url');
    this.settingsURL = this.data.get('settings-url');

    document.addEventListener('adobe_dc_view_sdk.ready', function() {
      this.previewPDF();
    }.bind(this));
  }

  previewPDF() {
    const adobeDCView = new AdobeDC.View({clientId: this.apiKey, divId: 'preview', backgroundColor: '#ffffff'});
    adobeDCView.previewFile(
        {
          content: {location: {url: this.pdfUrl()}},
          metaData: {fileName: this.data.get('file-name')},
        },
        {
          embedMode: 'SIZED_CONTAINER',
        },
    );
  }

  saveSettings() {
    this.previewButtonTarget.removeAttribute('disabled');

    $.ajax({
      type: 'put',
      url: this.settingsURL,
      data: {
        sendcloud_shipping_label_format: this.shippingLabelFormat(),
        sendcloud_shipping_label_position: this.shippingLabelPosition(),
      },
    });
  }

  // 'private' helper methods
  pdfUrl() {
    return `${this.baseURL}?format=${this.shippingLabelFormat()}&start_from=${this.shippingLabelPosition()}`;
  }

  shippingLabelFormat() {
    const checkedRadio = this.labelFormatTargets.find((e) => {
      return e.checked;
    });
    return checkedRadio && checkedRadio.value;
  }

  shippingLabelPosition() {
    const checkedRadio = this.labelPositionTargets.find((e) => {
      return e.checked;
    });
    return checkedRadio && checkedRadio.value;
  }
}
