import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'button'];

  connect() {
    this.toggleButton();
    this.fieldTarget.addEventListener('keyup', this.toggleButton.bind(this));
  }

  clear() {
    this.fieldTarget.value = '';
    this.toggleButton();
    this.fieldTarget.closest('form').submit();
  }

  toggleButton() {
    if (this.fieldTarget.value.trim().length > 0) {
      this.buttonTarget.classList.remove('d-none');
    } else {
      this.buttonTarget.classList.add('d-none');
    }
  }
}
