import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    if (this.isChrome()) {
      this.element.getElementsByTagName('input').forEach((input) => {
        if (input.autocomplete == 'off') {
          input.autocomplete = 'disabled';
        }
      });
    }
  }

  isChrome() {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }
}
