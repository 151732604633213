import $ from 'jquery';
import 'select2/dist/css/select2.css';
import 'select2';

$.fn.select2.amd.define('select2/i18n/nl',[],require("select2/src/js/select2/i18n/nl"));
$.fn.select2.amd.define('select2/i18n/fr',[],require("select2/src/js/select2/i18n/fr"));
$.fn.select2.amd.define('select2/i18n/de',[],require("select2/src/js/select2/i18n/de"));

import 'select2-bootstrap-theme/dist/select2-bootstrap.css';
$.fn.select2.defaults.set( "theme", "bootstrap" );
