// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery3
//= require popper
//= require bootstrap

require("@rails/ujs").start()

if (document.head.hasAttribute("data-turbolinks-pilot")) {
  require("turbolinks").start()
}

//require("@rails/activestorage").start()
//require("channels")

require('jquery')
window.$ = $

require ('packs/select2setup')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "url-polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "@fortawesome/fontawesome-free/css/all.css";
import '../stylesheets/application';
import './bootstrap_custom.js';
import 'whatwg-fetch';
//import * as $ from 'jquery';
import 'controllers';

import './swipers.js'
import './ie11_polyfill.js'
import './stickybits'
import '../images/empty.png'
import '../images/eu-logo-rect.png'
import './actiontext/initialize-trix.js'

import './actiontext/trix-editor-overrides.js'

import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;

import "chartkick"

// check if iOS
const isIOSPlatform = (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform))
  || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform));
let lastRun = new Date();

const calculateVH = () => { // set VH for iOS
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

if (isIOSPlatform) { // calculate VH for iOS (and recalculate on resize)
  $(window).resize(() => {
    const now = new Date();
    if (+now - +lastRun > 150) {
      calculateVH();
    }
  });
  calculateVH();

  if ('visualViewport' in window) {
    window.visualViewport.addEventListener('resize', function (event) {
      document.documentElement.style.setProperty('--vvhpx', `${window.visualViewport.height}px`);
    });
  }
}

// shows toast message
window.showToast = function (message, cssClass) {
  cssClass = cssClass || "alert alert-success";
  window.dispatchEvent(new CustomEvent("show-toast", { detail: { message: message, css_class: cssClass } }))
}

