import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['radio'];

  connect() {

  }
  select() {
    const cardSelector = this.data.get('card-selector') || '.shipment-option';
    const selectedClass = (this.data.get('selected-class') || 'selected').split(',');

    this.radioTarget.checked = true;
    document.querySelectorAll(`input[name=${this.radioTarget.name}]`).forEach((radio) => {
      radio.closest(cardSelector).classList.remove(...selectedClass);
    });
    this.radioTarget.closest(cardSelector).classList.add(...selectedClass);

    const button = document.querySelector(this.data.get('button-selector'));
    if (button) {
      button.disabled = false;
    }
  }
}
