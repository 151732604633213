import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    this.element.addEventListener('keyup', this.clearError.bind(this));
  }

  clearError() {
    this.element.parentElement.querySelector(".is-invalid").classList.remove("is-invalid");
  }
}
