import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    const sticky = document.querySelector('.sticky');
    const handler = (entries) => {
      if (!entries[0].isIntersecting) {
        this.element.classList.add('is-pinned');
      } else {
        this.element.classList.remove('is-pinned');
      }
    };

    // create the observer
    const observer = new window.IntersectionObserver(handler);
    // give the observer some dom nodes to keep an eye on
    observer.observe(sticky);
  }
}
