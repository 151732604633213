import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['title', 'translated'];

  show() {
    this.titleTarget.hidden = false;
    this.translatedTarget.hidden = true;
  }

  hide() {
    this.titleTarget.hidden = true;
    this.translatedTarget.hidden = false;
  }
}
