import {Controller} from 'stimulus';

export default class SelectToggleController extends Controller {
  static targets = ['source'];

  connect() {
    this.toggle();
  }

  toggle() {
    let sourceValue = null;
    if (this.hasSourceTarget == false) {
      return;
    }
    if (this.sourceTarget.type == 'radio') {
      const checkedRadio = this.sourceTargets.find((e) => {
        return e.checked;
      });
      sourceValue = checkedRadio && checkedRadio.value;
    } else {
      sourceValue = this.sourceTarget.value;
    }

    if (this.showValues().includes(sourceValue)) {
      this.element.querySelectorAll(this.data.get('show-selector')).forEach((e) => {
        e.classList.remove('d-none');
      });
      this.element.querySelectorAll(this.data.get('hide-selector')).forEach((e) => {
        e.classList.add('d-none');
      });
    } else {
      this.element.querySelectorAll(this.data.get('show-selector')).forEach((e) => {
        e.classList.add('d-none');
      });
      this.element.querySelectorAll(this.data.get('hide-selector')).forEach((e) => {
        e.classList.remove('d-none');
      });
    }
  }

  showValues() {
    if (this.data.has('show-value')) {
      return this.data.get('show-value').split(',') || [];
    } else {
      return [];
    }
  }
}
