import {Controller} from 'stimulus';
export default class extends Controller {
  static targets = ['input'];
  static values = {translations: String}


  connect() {
    this.translations = this.translationsValue.split(',');
    $.fn.multiselect.Constructor.prototype.defaults.selectAllText = this.translations[0];
    $.fn.multiselect.Constructor.prototype.defaults.filterPlaceholder = this.translations[1];
    $.fn.multiselect.Constructor.prototype.defaults.nonSelectedText = this.translations[2];
    $.fn.multiselect.Constructor.prototype.defaults.nSelectedText = this.translations[3];
    $.fn.multiselect.Constructor.prototype.defaults.allSelectedText = this.translations[4];

    $(this.element).multiselect({
      // enableFiltering: true,
      enableClickableOptGroups: true,
      enableCaseInsensitiveFiltering: true,
      maxHeight: '33vh',
      buttonContainer: '<div class="btn-group w-100 dropdown" />',
      buttonTextAlignment: 'left',
      numberDisplayed: 7,
      filterPlaceholder: this.translations[1],
      enableHTML: true,
      onDropdownShown: (event) => {
        $(this.element).parent().find('input[type=\'text\'].multiselect-search').trigger('focus');
      },
      onDropdownHidden: (event) => {
        $(this.element).parent().find('button.multiselect-clear-filter').trigger('click');
      },
      templates: {
        filter: '<div class="multiselect-filter">' +
                '    <input class="form-control multiselect-search" type="text" />' +
                '    <button class="btn btn-icon multiselect-clear-filter" type="button">' +
                '      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="red" width="14px" height="14px"><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM7.43,6.67A1.07,1.07,0,0,1,9,6.67l2.86,2.86a.28.28,0,0,0,.38,0l2.86-2.86a1.07,1.07,0,0,1,1.52,0l.76.76a1.07,1.07,0,0,1,0,1.52l-2.86,2.86a.28.28,0,0,0,0,.38l2.86,2.86a1.07,1.07,0,0,1,0,1.52l-.76.76a1.07,1.07,0,0,1-1.52,0l-2.86-2.86a.28.28,0,0,0-.38,0L9,17.33a1.07,1.07,0,0,1-1.52,0l-.76-.76a1.07,1.07,0,0,1,0-1.52l2.86-2.86a.28.28,0,0,0,0-.38L6.67,9a1.07,1.07,0,0,1,0-1.52Z"></path></svg>' +
                '    </button>' +
                '</div>',
      },
    });
  }
}

require('../packs/bootstrap-multiselect-1.1.1');
