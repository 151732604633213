import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['image'];

  zoom() {
    document.querySelector('.lightbox').classList.remove('d-none');
    document.body.classList.add('no-scroll');

    const imageString = '<img src="' +
                        this.imageTarget.src.replace('representations', 'blobs') +
                        '" class: "swiper-lazy"/>';
    document.querySelector('.swiper-zoom-container').innerHTML = imageString;
  }
}
