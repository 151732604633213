import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['input']

  copy(event) {
    if (this.hasInputTarget) {
      this.inputTarget.value = event.target.getAttribute('data-set-value-value-param');
    }
  }
}
