import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    $(window).on('popstate', function(event) {
      const backUrl = document.getElementById('backurl').getAttribute('data-back-url');
      if (backUrl == window.location.href) {
        window.location = backUrl;
      }
    });

    const currentUrl = window.location.href;
    window.history.pushState({prevUrl: this.data.get('url')}, '', this.data.get('url'));
    window.history.pushState({prevUrl: this.data.get('url')}, '', currentUrl);
  }
}
