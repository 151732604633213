import {Controller} from 'stimulus';
import {MobileAppHelper} from '../helpers/mobile_app_helper';

let tokenRequest;

export default class extends Controller {
  static targets = ['amount', 'id', 'shop_name', 'one', 'set', 'max',
    'gone', 'card', 'ids', 'delete_item', 'delete_oos', 'max_amount', 'summary'];
  static values = {summaryPartialPath: String}

  connect() {
    this.fetchToken((token)=> {
      this.token = token;
    });
  }

  up() {
    this.amountTarget.value++;
    this.postNewAmount();
  }

  down() {
    this.amountTarget.value--;
    this.postNewAmount();
  }

  delete() {
    const result = confirm(this.delete_itemTarget.value);
    if (result == true) {
      this.deleteOrderLine(this.idTarget.value);
    }
  }

  deleteOos() {
    const result = confirm(this.delete_oosTarget.value);
    if (result == true) {
      const ids = this.idsTarget.value.split(',');
      ids.forEach((idTarget) => {
        this.deleteOrderLine(idTarget);
      });
      document.querySelector('.alert.alert-danger.alert-match').hidden= true;
    }
  }

  oosRemovedFromList(id) {
    const ids = document.getElementById('ids');

    if (ids != null) {
      const idsArray = ids.value.split(',').filter((idValue) => idValue != id);
      if (idsArray.length == 0) {
        document.querySelector('.alert.alert-danger.alert-match').hidden= true;
        $('#oosModal').modal('hide');
      }
      ids.value = idsArray.join();
    }
  }

  postNewAmount() {
    const updateHash = {};
    updateHash[this.idTarget.value] = this.amountTarget.value;

    const data = {
      authenticity_token: this.token,
      updates: updateHash,
      user: this.shop_nameTarget.value,
      r6: '1',
    };
    const url = '/shop/update_order';

    fetch( url,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        },
    ).then((response) => response.json())
        .then((data) => {
          if (data.status == 'ok') {
            this.maxTarget.hidden = true;
            if (this.amountTarget.value == 0) {
              this.delete();
            }
          }
          if (data.status == 'exact') {
            this.maxTarget.hidden = false;
          }
          if (data.status == 'error') {
            if (parseInt(this.amountTarget.value) >= parseInt(this.max_amountTarget.value)) {
              this.amountTarget.value = this.max_amountTarget.value;
              this.postNewAmount();
            }
            if (this.amountTarget.value == '1') {
              this.oneTarget.hidden = false;
              this.setTarget.hidden = true;
            } else {
              this.maxTarget.hidden = false;
            }
          }
          if (data.status == 'oos') {
            this.setTarget.hidden = true;
            this.goneTarget.hidden = false;
          }

          this.updateCartDropdown(data);
          this.updateSummary();
        });
  }

  deleteOrderLine(id) {
    const url = '/shop/delete_line/' + id + '?user=' + this.shop_nameTarget.value;
    const data = {
      authenticity_token: this.token,
      r6: '1',
    };
    fetch( url,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        },
    ).then((response) => response.json())
        .then((data) => {
          if (data.status == 'ok') {
            this.oosRemovedFromList(id);
            if (this.hasCardTarget) {
              this.cardTarget.remove();
            }
          }
          if (data.status == 'error') {
            if (!this.hasCardTarget) {
              document.querySelector('.alert.alert-danger.alert-match').hidden= true;
            }
          }

          this.updateCartDropdown(data);
          this.updateSummary();
        });
  }

  fetchToken(callback) {
    if (tokenRequest) {
      return tokenRequest.then(callback);
    }
    tokenRequest = fetch('/csrf_token')
        .then((res) => res.text());

    tokenRequest.then(callback);
  }

  updateCartDropdown(summary) {
    const cartDropdown = document.getElementById('shopping_cart_dropdown_count');
    if (cartDropdown) {
      cartDropdown.innerHTML = summary.total_quantity;
    }
    MobileAppHelper.updateShoppingCartCount(summary.total_quantity);
  }

  updateSummary() {
    this.shoppingCartSummary = document.getElementById('shopping_cart_summary');
    fetch(this.summaryPartialPathValue)
        .then((response) => response.text())
        .then((html) => {
          this.shoppingCartSummary.innerHTML = html;
          if (html.includes('minimumOrderAmountNotReached')) {
            document.getElementById('minimumOrderButton').hidden = false;
            document.getElementById('orderButton').hidden = true;
          } else {
            document.getElementById('minimumOrderButton').hidden = true;
            document.getElementById('orderButton').hidden = false;
          }
        });
  }
}
