import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['message', 'wrapper'];

  removeTimeout;

  connect() {
    window.addEventListener('show-toast', (e) => {
      clearTimeout(this.removeTimeout);
      this.messageTarget.innerHTML = e.detail.message;
      if (this.hasWrapperTarget) {
        if (e.detail.css_class) {
          this.wrapperTarget.className = e.detail.css_class;
        }
      }
      setTimeout(() => {
        this.element.classList.remove('out');
        this.element.classList.add('in');
      }, 100);

      this.removeTimeout = setTimeout(() => {
        this.element.classList.remove('in');
        this.element.classList.add('out');
      }, 10000);
    });
  }

  close() {
    clearTimeout(this.removeTimeout);
    this.element.classList.remove('in');
    this.element.classList.add('out');
  }
}
