import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    if (window.location.hash) {
      const target = document.querySelector(window.location.hash);
      const collapsable = $('#' + target.getAttribute('data-collapsable'));
      console.debug(collapsable);
      collapsable.collapse('show');
    }
  }

  setAnchor(event) {
    history.pushState(null, null, '#' + event.currentTarget.getAttribute('data-anchor-id'));
  }
}
