import {Controller} from 'stimulus';

export default class LinkedButtonController extends Controller {
  static targets = ['button', 'field'];

  connect() {
    this.checkFields();
  }

  checkFields(e) {
    // remove validation error classes if present, the field changed
    if (e && e.target.value) {
      const invalidIndicator = e.target.closest('.is-invalid');
      if (invalidIndicator) {
        invalidIndicator.classList.remove('is-invalid');
        const inputGroup = e.target.closest('.input-group');
        if (inputGroup) {
          inputGroup.classList.remove('is-invalid');
        }
      }
    }

    const radioButtons = this.fieldTargets.filter((field) => field.type == 'radio');
    const radioButtonNames = [...new Set([...radioButtons].map((field) => field.name))];
    const uncheckedRadioButtonGroup = radioButtonNames.find((name) => !document.querySelector('input[name="'+name+'"]:checked'));

    const otherInputs = this.fieldTargets.filter((field) => field.type != 'radio');
    const foundEmptyField = otherInputs.find((field) => !field.value);

    this.buttonTargets.forEach((button) => {
      button.disabled = uncheckedRadioButtonGroup || foundEmptyField;
    });
  }
}
