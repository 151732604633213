import {Controller} from 'stimulus';
import LazyLoad from 'vanilla-lazyload';

export default class extends Controller {
  connect() {
    new LazyLoad({
      elements_selector: 'img[data-src]:not(.loaded)',
    });
  }
}
