import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'source', 'spinner']

  connect() {
    const url = this.data.get('url');
    console.debug('remote content from: ' + url);
  }

  load() {
    let url = this.data.get('url');
    console.debug('load remote content from: ' + url);
    if (this.data.get('param')) {
      url += `?${this.data.get('param')}=${this.sourceTarget.value}`;
    }

    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('d-none');
    }

    fetch(url)
        .then((response) => response.text())
        .then((html) => {
          if (this.hasSpinnerTarget) {
            this.spinnerTarget.classList.remove('d-none');
          }
          this.containerTarget.innerHTML = html;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          if (this.hasSpinnerTarget) {
            this.spinnerTarget.classList.add('d-none');
          }
        });
  }
}
