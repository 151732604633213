import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    const options = {
      boundary: 'window',
    };

    if (this.element.dataset.color) {
      options['template'] = '<div class="tooltip ' +
                            this.element.dataset.color +
                            '" role="tooltip">' +
                            '  <div class="arrow"></div>' +
                            '  <div class="tooltip-inner"></div>' +
                            '</div>';
    }

    $(this.element).tooltip(options);
  }
}
