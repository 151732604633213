import {Controller} from 'stimulus';

export default class extends Controller {
  static values = {
    seperator: String,
  }

  format(event) {
    if (event.target.value) {
      event.target.value = Number(event.target.value.replace(this.seperatorValue, '.')).toFixed(2);
      event.target.value = event.target.value.replace('.', this.seperatorValue);
    }
  }
}
