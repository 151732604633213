import {Controller} from 'stimulus';


export default class extends Controller {
  static targets = ['input', 'count', 'total', 'select', 'image', 'empty', 'overflow', 'type', 'user', 'submit'];
  static values = {translations: String}

  connect() {
    this.selectedCategories = [];
    this.translations = this.translationsValue.split(',');
    $.fn.multiselect.Constructor.prototype.defaults.selectAllText = this.translations[0];
    $.fn.multiselect.Constructor.prototype.defaults.filterPlaceholder = this.translations[1];
    $.fn.multiselect.Constructor.prototype.defaults.nonSelectedText = this.translations[2];
    $.fn.multiselect.Constructor.prototype.defaults.nSelectedText = this.translations[3];
    $.fn.multiselect.Constructor.prototype.defaults.allSelectedText = this.translations[4];

    $(this.selectTarget).multiselect({
      // enableFiltering: true,
      enableClickableOptGroups: true,
      enableCaseInsensitiveFiltering: true,
      maxHeight: 245,
      buttonContainer: '<div class="btn-group w-100 dropdown" />',
      buttonTextAlignment: 'left',
      numberDisplayed: 7,
      filterPlaceholder: this.translations[1],
      includeSelectAllOption: true,
      enableHTML: true,
      templates: {
        filter: '<div class="multiselect-filter">' +
                '  <div class="input-group input-group-sm">' +
                '    <input class="form-control multiselect-search" type="text" />' +
                '    <div class="input-group-append">' +
                '      <button class="multiselect-clear-filter input-group-text" type="button">' +
                '        <i class="fas fa-times"></i>' +
                '      </button>' +
                '    </div>' +
                '  </div>' +
                '</div>',
      },
      onSelectAll: function(option, checked, select) {
        this.selectedCategories = $(this.selectTarget).val();
        this.update();
      }.bind(this),
      onDeselectAll: function(option, checked, select) {
        this.selectedCategories = $(this.selectTarget).val();
        this.update();
      }.bind(this),
      onChange: function(option, checked, select) {
        this.selectedCategories = $(this.selectTarget).val();
        this.update();
      }.bind(this),
    }).bind(this);
  }

  update() {
    let query_string = '';
    this.selectedCategories.forEach((category) => query_string += '&categories[]=' + category);
    query_string += '&type=' + this.typeTarget.value;
    query_string += '&user_name=' + this.userTarget.value;

    query_string = query_string.replace('\&', '?');
    const url = '../collection/export/category' + query_string;

    const self = this;
    fetch( url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
    ).then((response) => response.json())
        .then((data) => {
          if (data[2].length == 0) {
            self.emptyTarget.hidden = false;
            if (this.hasSubmitTarget) {
              self.submitTarget.disabled = true;
            }
          } else {
            self.emptyTarget.hidden = true;
            if (this.hasSubmitTarget) {
              self.submitTarget.disabled = false;
            }
          }
          self.countTargets.forEach( (elem) => {
            elem.textContent = data[0];
          } );
          self.totalTargets.forEach( (elem) => {
            elem.textContent = data[1];
          } );
          if (data[1] - 13 > 0) {
            self.overflowTarget.hidden = false;
            self.overflowTarget.textContent = '+ ' + (data[1] - 13);
          } else {
            self.overflowTarget.hidden = true;
          }

          self.imageTargets.forEach( (element, index) => {
            let url = '';
            if (data[2][index]) {
              element.hidden = false;
              url = data[2][index];
            } else {
              element.hidden = true;
            }
            element.src = url;
            // self.imageTargets[index].src = 'https://assets.lastdodo.com/image/ld_medium/plain/assets/catalog/assets/2022/5/12/a/7/b/pdf_a7bf5a22-d1cf-11ec-939d-7d51a455467f.jpg';
          });
        });
  }
}

require('../packs/bootstrap-multiselect-1.1.1');
