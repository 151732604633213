import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['query', 'container']

  initialize() {
    console.debug('item_complete');
    this.search = this.debounce(this.search.bind(this), 500);
  }

  debounce(callback, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(this, args), wait);
    };
  }

  search() {
    const query = this.queryTarget.value;
    console.debug('search: ' + query);
    if (query.length > 2) {
      this.containerTarget.classList.remove('d-none');
    } else {
      this.containerTarget.classList.add('d-none');
    }
  }

  close() {
    this.containerTarget.classList.add('d-none');
  }
}
