import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['price', 'shipping', 'discount', 'total']
  static values = {subtotal: Number}

  update() {
    console.log(this.subtotalValue);
    const currency = this.totalTarget.innerText.substring(0, 1);
    const commaSeperator = this.priceTarget.innerText.substring(2).includes(',');
    const price = Number(this.subtotalValue);

    let discount = Number(this.discountTarget.value.replace(',', '.'));
    let shipping = Number(this.shippingTarget.value.replace(',', '.'));

    if (isNaN(discount)) {
      discount = 0;
    }
    if (isNaN(shipping)) {
      shipping = 0;
    }

    this.totalTarget.innerText = currency.concat(' ', (price + shipping - discount )
        .toFixed(2));

    if (commaSeperator) {
      this.totalTarget.innerText = this.totalTarget.innerText.replace('.', ',');
    }
  }
}
