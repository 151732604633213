import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const showModal = document.cookie.indexOf('age_check') === -1;

    if (showModal) {
      setTimeout(function () {
        $('#age_check_modal').modal('show');
      }, 500);
    }
  }
}
