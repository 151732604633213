import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['source', 'meta']

  copy() {
    if (this.sourceTarget.value == null) {
      navigator.clipboard.writeText(this.sourceTarget.innerHTML.replaceAll('<br>', '\r\n'));
    } else {
      navigator.clipboard.writeText(this.sourceTarget.value.replaceAll('<br>', '\r\n'));
    }

    window.dispatchEvent(
        new CustomEvent('show-toast',
            {
              detail: {
                message: this.metaTarget.value,
                css_class: 'alert alert-success',
              },
            },
        ),
    );
  }
}
