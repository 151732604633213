import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['analytics', 'preferences', 'marketing', 'modal', 'customize', 'acceptSelection', 'consentOptions']

  connect() {
    const showCookieBar = localStorage.getItem('consentMode') === null;
    const alreadyAccepted = document.cookie.indexOf('cw_to_ld_modal_viewed=true') >= 0;

    if (showCookieBar) {
      if (alreadyAccepted) {
        this.acceptAll();
        return;
      }
      this.element.classList.add('show');
      document.body.classList.add('cookie-visible');

      $(this.element).on('click', (event) => {
        event.stopPropagation();
      });
    }
  }

  acceptAll() {
    this.setConsent({
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true,
    });
    this.hideBanner();
  }

  customize() {
    this.customizeTarget.hidden=true;
    this.acceptSelectionTarget.hidden = false;
    this.consentOptionsTarget.style.display = 'block';
  }

  acceptSelection() {
    this.setConsent({
      necessary: true,
      analytics: this.analyticsTarget.checked,
      preferences: this.preferencesTarget.checked,
      marketing: this.marketingTarget.checked,
    });
    this.hideBanner();
  }

  rejectAll() {
    this.setConsent({
      necessary: true,
      analytics: false,
      preferences: false,
      marketing: false,
    });
    this.hideBanner();
  }

  hideBanner() {
    this.modalTarget.classList.remove('show');
    document.body.classList.remove('cookie-visible');
  }

  setConsent(consent) {
    const consentMode = {
      'functionality_storage': consent.necessary ? 'granted' : 'denied',
      'security_storage': consent.necessary ? 'granted' : 'denied',
      'ad_storage': consent.marketing ? 'granted' : 'denied',
      'ad_user_data': consent.marketing ? 'granted' : 'denied',
      'ad_personalization': consent.marketing ? 'granted' : 'denied',
      'analytics_storage': consent.analytics ? 'granted' : 'denied',
      'personalization_storage': consent.preferences ? 'granted' : 'denied',
    };
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
    this.gtag('consent', 'update', consentMode);
  }

  gtag() {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push(arguments);
  }
}
