import {Datepicker} from 'vanillajs-datepicker';
import nl from 'vanillajs-datepicker/js/i18n/locales/nl.js';
import de from 'vanillajs-datepicker/js/i18n/locales/de.js';
import fr from 'vanillajs-datepicker/js/i18n/locales/fr.js';

import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    Object.assign(Datepicker.locales, nl, de, fr);
    const datepicker = new Datepicker(this.element, {
      format: 'dd-mm-yyyy',
      buttonClass: 'btn',
      language: document.documentElement.getAttribute('lang'),
    });
  }
}
