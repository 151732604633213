import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['country', 'address_section'];
  static values = {path: String}

  change() {
    $.ajax({
      url: this.pathValue + '?country_id=' + this.countryTarget.value,
      type: 'get',
    },
    ).done((result) => {
      this.address_sectionTarget.innerHTML = result;
    });
  }
}
