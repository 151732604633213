import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['images'];

  focus(e) {
    const new_index = e.currentTarget.getAttribute('data-info-slider-value-index-value');

    for (const [index, image] of this.imagesTargets.entries()) {
      if (index == new_index ) {
        image.classList.add('show');
      } else {
        image.classList.remove('show');
      }
    }
  }
}
