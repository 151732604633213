import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['negative', 'neutral', 'submitButton', 'remark', 'contact', 'notResponding',
    'fraud', 'feedback', 'step2', 'conditional']
  static lastStatus = 0;

  connect() {
    $('#giveFeedBackModal').on('shown.bs.modal', function(e) {
      setTimeout(() => {
        $(this).addClass('active');
      }, 250);
    });

    $('#giveFeedBackModal').on('hide.bs.modal', function(e) {
      $(this).removeClass('active');
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('request_feedback')) {
      $('#giveFeedBackModal').modal('show');
    }
    if (urlParams.has('respond_to_feedback')) {
      if (document.getElementsByClassName('side-panel').length != 0) {
        this.show();
      }
    }
  }

  show() {
    document.body.classList.add('panel-feedback-show');
    document.body.classList.add('no-scroll');

    if (document.getElementById('feedback_panel') == null) {
      $( 'body' ).append(
          '<div id="feedback_panel" ' +
        'data-action="click->feedback#hide" ' +
        'class="side-panel-backdrop fade"></div>',
      );
    }
    setTimeout(function() {
      $( 'body .side-panel-backdrop.fade' ).addClass( 'show' );
    }, 10);

    const self = this;
    $('.side-panel-backdrop').on('click', function() {
      self.hide();
    });
  }

  hide() {
    document.body.classList.remove('panel-feedback-show');
    document.body.classList.remove('no-scroll');
    $( 'body .side-panel-backdrop.fade' ).removeClass( 'show' );
    setTimeout(function() {
      $( 'body .side-panel-backdrop.fade' ).remove();
    }, 200);
  }

  positive() {
    this.negativeTarget.hidden = true;
    this.step2Targets.forEach((infoTarget) => infoTarget.hidden = true);
    this.conditionalTarget.hidden = false;
    this.contactTarget.disabled = true;
    this.notRespondingTarget.disabled = true;
    this.fraudTarget.disabled = true;
    this.remarkTarget.required = false;
    this.lastStatus = 1;
    this.remark();
  }

  negative() {
    this.negativeTarget.hidden = false;
    this.conditionalTarget.hidden = true;
    this.contactTarget.disabled = false;
    if (this.contactTarget.checked) {
      this.step2Targets.forEach((infoTarget) => infoTarget.hidden = false);
      this.conditionalTarget.hidden = false;
      this.notRespondingTarget.disabled = false;
      this.fraudTarget.disabled = false;
    } else {
      this.step2Targets.forEach((infoTarget) => infoTarget.hidden = true);
      this.conditionalTarget.hidden = true;
      this.notRespondingTarget.disabled = true;
      this.fraudTarget.disabled = true;
    }

    this.remarkTarget.required = true;
    this.lastStatus = 3;
    this.remark();
  }

  neutral() {
    this.negativeTarget.hidden = true;
    this.step2Targets.forEach((infoTarget) => infoTarget.hidden = true);
    this.conditionalTarget.hidden = false;
    this.remarkTarget.required = true;
    this.contactTarget.disabled = true;
    this.notRespondingTarget.disabled = true;
    this.fraudTarget.disabled = true;
    this.lastStatus = 2;
    this.remark();
  }

  remark() {
    if (this.lastStatus == 1) {
      this.submitButtonTarget.disabled = false;
    }
    if (this.lastStatus == 2) {
      this.submitButtonTarget.disabled = this.remarkTarget.value.length < 5;
    }
    if (this.lastStatus == 3) {
      this.submitButtonTarget.disabled = !this.contactTarget.checked ||
                                         this.remarkTarget.value.length < 5;
    }
  }

  details() {
    window.location.href = '../shop_orders/' +
                           this.data.get('feedback-id') +
                           '?respond_to_feedback';
  }

  // open() {
  //   if (this.data.get('feedback-id')) {
  //     const feedbackId = this.data.get('feedback-id');

  //     $.ajax({
  //       url: 'feedback/panel/' + feedbackId,
  //       type: 'get',
  //     }).done((result) => {
  //       eval(result);
  //     });

  //     this.show();
  //   }
  // }
}
