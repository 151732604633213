import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['onRequest', 'shippingCost'];
  static values = {free: String, onRequest: String}

  onRequestClick() {
    this.shippingCostTarget.value = '';
    if (this.onRequestTarget.checked) {
      this.shippingCostTarget.placeholder = this.onRequestValue;
      this.shippingCostTarget.readOnly = true;
    } else {
      this.shippingCostTarget.placeholder = this.freeValue;
      this.shippingCostTarget.readOnly = false;
    }
  }
}
