import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['hide']

  connect() {
    $('#allAreasDropdownWrap').on('show.bs.dropdown', function() {
      document.body.classList.add('no-scroll');
      $( 'body' ).append( '<div class="dropdown-backdrop"></div>' );
      setTimeout(function() {
        $( 'body .dropdown-backdrop' ).addClass( 'show' );
      }, 10);
    });
    $('#allAreasDropdownWrap').on('hide.bs.dropdown', function() {
      document.body.classList.remove('no-scroll');
      $( 'body .dropdown-backdrop' ).removeClass( 'show' );
      setTimeout(function() {
        $( 'body .dropdown-backdrop' ).remove();
      }, 200);
    });
    $('#cartDropdownWrap').on('show.bs.dropdown', ()=>{
      this.hideElement();
    });
    $('#cartDropdownWrap').on('hide.bs.dropdown', ()=>{
      this.showElement();
    });
  }

  hideElement() {
    if (this.hasHideTarget) {
      this.hideTarget.hidden = true;
    }
  }

  showElement() {
    if (this.hasHideTarget) {
      this.hideTarget.hidden = false;
    }
  }
}
