import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['mainImage', 'lightbox', 'externalThumbnails', 'internalThumbnails']

  show() {
    this.lightboxTarget.classList.remove('d-none');
    document.body.classList.add('no-scroll');
    if (this.hasExternalThumbnailsTarget) {
      this.lightboxTarget.dispatchEvent(new CustomEvent('openWith', {
        detail: this.externalThumbnailsTarget.querySelector('.active').dataset.index,
      }));
    } else {
      this.lightboxTarget.dispatchEvent(new CustomEvent('openWith', {detail: 0}));
    }
  }

  hide(e) {
    e.preventDefault();
    this.lightboxTarget.classList.add('d-none');
    document.body.classList.remove('no-scroll');
    this.lightboxTarget.dispatchEvent(new CustomEvent('lightboxHide'));
  }

  navigate(e) {
    switch (e.keyCode) {
      case 27:
        this.hide();
        break;
    }
  }
}
