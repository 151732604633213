

export class MobileAppHelper {

  static isApp() {    
    return document.body.classList.contains('mobile-app') && !!MobileAppHelper.messageBase();
  }

  static messageBase() {
    return window.ReactNativeWebView;
  }

  static updateShoppingCartCount(count) {
    if (MobileAppHelper.isApp()) {
      const event = {
        event_type: 'shoppingcart_contents',
        timestamp: new Date(),
        payload: {
          count: count ? parseInt(count, 0) : 0,
        },
      };
      MobileAppHelper.messageBase().postMessage(JSON.stringify(event));
    }
  }

  static updateRouting(data) {
    if (MobileAppHelper.isApp()) {
      const event = {
        event_type: 'route_change',
        timestamp: new Date(),
        payload: {
          route: data.route, // home, collection, marketplace, shopping_cart, user
          is_top_level: data.is_top_level, // e.g. true for root pages of 'home', 'marketplace', etc, false for all subpages,
          title: data.title,
          logo_bar: data.logo_bar,
          page_bar: data.page_bar,
          nav_bar: data.nav_bar,
          search_bar: data.search_bar,
          search_scope: data.search_scope, 
          auto_hide_search_bar: data.auto_hide_search_bar,
          locale: data.locale,
          shopping_cart: data.shopping_cart,
        },
      };
      MobileAppHelper.messageBase().postMessage(JSON.stringify(event));
    }
  }

  static updateLoginStatus(avatarUrl) {
    if (MobileAppHelper.isApp()) {
      const event = {
        event_type: 'authentication_status',
        timestamp: new Date(),
        payload: {
          is_logged_in: !!avatarUrl,
          avatar_url: avatarUrl,
        },
      }
      MobileAppHelper.messageBase().postMessage(JSON.stringify(event));
    }
  }

  static debug() {
    console.log("Debug enabled")
    if (MobileAppHelper.isApp()) {
      const event = {
        event_type: 'debug',
        timestamp: new Date(),
        payload: {
          show: true
        },
      }
      MobileAppHelper.messageBase().postMessage(JSON.stringify(event));
    }
  }

}
