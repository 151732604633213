import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    const showModal = document.cookie.indexOf('cw_to_ld_modal_viewed=true') === -1;

    if (showModal) {
      document.cookie = 'cw_to_ld_modal_viewed=true; ' +
                        'path=/ ;expires=Fri, 31 Dec 9999 23:59:59 GMT';
      setTimeout(function() {
        $('#CWtoLDmodal').modal('show');
      }, 500);
    }
  }
}
