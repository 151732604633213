import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['searchbox'];

  open() {
    document.getElementsByTagName('body')[0].classList.add('show-search-overlay');
    this.searchboxTarget.focus();
  }
  close() {
    document.getElementsByTagName('body')[0].classList.remove('show-search-overlay');
  }
}
