import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['link'];

  connect() {
    if (this.data.has('cookie') && document.cookie.indexOf(this.data.get('cookie')+'=true') != -1) {
      this.disable();
    }
  }

  disable() {
    this.linkTargets.forEach((link) => {
      link.setAttribute('data-turbolinks', false);
    });
  }
}
