import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['modal', 'message', 'default', 'previous']

  resetDefault() {
    this.messageTarget.value = this.defaultTarget.value;
  }

  resetPrevious() {
    this.messageTarget.value = this.previousTarget.value;
  }

  save() {
    this.previousTarget.value = this.messageTarget.value;
    $('#'.concat(this.modalTarget.id)).modal('hide');
  }
}
