import Swiper, { Navigation, Lazy, Keyboard, Scrollbar, Pagination, Thumbs, Zoom } from 'swiper';
Swiper.use([Navigation, Lazy, Keyboard, Scrollbar, Pagination, Thumbs, Zoom]);
$(()=>{
  let isMobile = document.body.classList.contains('mobile-app');
  let checkSwiperNavigation = function(swiper) {
    let $swiperContainer = $(swiper.wrapperEl).parents('.swiper-container:first');
    let $arrowLeft = $swiperContainer.find(swiper.params.navigation.prevEl);
    let $arrowRight = $swiperContainer.find(swiper.params.navigation.nextEl);
    $arrowLeft.removeClass('disabled');
    $arrowRight.removeClass('disabled');
    if (swiper.isBeginning) {
      $arrowLeft.addClass('disabled');  
    } 
    if (swiper.isEnd) {
      $arrowRight.addClass('disabled');  
    }
  };
  let swiperConfigs = {
    '.similar-swiper': {
      slidesPerView: 2.5,
      slidesPerGroup: 2,
      spaceBetween: 16,
      lazy: {
        loadPrevNext: true,
      },
      breakpoints: {
        576: {
          slidesPerView: 4,
          slidesPerGroup: 2,
        },
        768: {
          slidesPerView: 5,
          slidesPerGroup: 2,
        },
        992: {
          slidesPerView: 6,
          slidesPerGroup: 2,
        },
        1200: {
          slidesPerView: 8,
          slidesPerGroup: 8,
        },
      },
      on: {
        lazyImageReady: function(swiper, slideEl, imageEl) {
          if ('objectFit' in document.documentElement.style === false) {
            (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
            slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
          }
        },
        // init: checkSwiperNavigation,     
        // slideChange: checkSwiperNavigation
      }
    },
    '.catalog-swiper': {
    slidesPerView: 2.25,
    slidesPerGroup: 2,
    spaceBetween: 16,
    navigation: {
      nextEl: '.catalog-swiper-next',
      prevEl: '.catalog-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
    },
    breakpoints: isMobile ? {
      576: {
        slidesPerView: 4,
        slidesPerGroup: 3,
      },
      768: {
        slidesPerView: 6,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 8,
        slidesPerGroup: 5,
      },
    } : {
      576: {
        slidesPerView: 4,
        slidesPerGroup: 3,
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 6,
        slidesPerGroup: 5,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.auction-swiper': {
    slidesPerView: 2.25,
    slidesPerGroup: 2,
    spaceBetween: 16,
    observer: true,
    navigation: {
      nextEl: '.auction-swiper-next',
      prevEl: '.auction-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      // loadPrevNextAmount: 6,
    },
    breakpoints: {
      576: {
        slidesPerView: 4,
        slidesPerGroup: 2,
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 6,
        slidesPerGroup: 5,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.catalog-3-swiper': {
    slidesPerView: 2.25,
    slidesPerGroup: 2,
    spaceBetween: 16,
    navigation: {
      nextEl: '.catalog-3-swiper-next',
      prevEl: '.catalog-3-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      // loadPrevNextAmount: 3,
    },
    breakpoints: {
      576: {
        slidesPerView: 2.25,
        slidesPerGroup: 2,
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 2,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.catalog-5-swiper': {
    slidesPerView: 2.25,
    slidesPerGroup: 2,
    spaceBetween: 16,
    navigation: {
      nextEl: '.catalog-5-swiper-next',
      prevEl: '.catalog-5-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      // loadPrevNextAmount: 3,
    },
    breakpoints: {
      576: {
        slidesPerView: 2.25,
        slidesPerGroup: 2,
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 5,
        slidesPerGroup: 4,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.areas-swiper': {
    slidesPerView: 2.25,
    slidesPerGroup: 2,
    spaceBetween: 16,
    slidesPerColumn: 1,
    slidesPerColumnFill: 'column',
    allowSlidePrev: true,
    allowSlideNext: true,
    watchOverflow: true,
    navigation: {
      nextEl: '.areas-swiper-next',
      prevEl: '.areas-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    breakpoints: {
      576: {
        slidesPerView: 3.5,
        slidesPerGroup: 3,
        spaceBetween: 16,
        slidesPerColumn: 1,
        allowSlidePrev: true,
        allowSlideNext: true,
        slidesPerColumnFill: 'column',
      },
      768: {
        slidesPerView: 4.5,
        slidesPerGroup: 4,
        spaceBetween: 16,
        slidesPerColumn: 1,
        allowSlidePrev: true,
        allowSlideNext: true,
        slidesPerColumnFill: 'column',
      },
      992: {
        slidesPerView: 4,
        slidesPerGroup: 3,
        spaceBetween: 0,
        slidesPerColumn: 2,
        allowSlidePrev: false,
        allowSlideNext: false,
        slidesPerColumnFill: 'row',
      },
    },
  },
  '.home-shop-swiper': {
    slidesPerView: 1.131,
    slidesPerGroup: 1,
    slidesPerColumn: 1,
    spaceBetween: 16,
    slidesPerColumnFill: 'row',
    navigation: {
      nextEl: '.home-shop-swiper-next',
      prevEl: '.home-shop-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 12,
    },
    breakpoints: {
      768: {
        slidesPerView: 1.131,
        slidesPerGroup: 1.131,
        slidesPerColumn: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 2,
        slidesPerColumn: 2,
      },
    },
    on: {
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.shop-swiper': {
    slidesPerView: 3.2,
    slidesPerGroup: 3,
    slidesPerColumn: 1,
    spaceBetween: 30,
    slidesPerColumnFill: 'row',
    navigation: {
      nextEl: '.shop-swiper-next',
      prevEl: '.shop-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 12,
    },
    breakpoints: {
      768: {
        slidesPerView:3,
        slidesPerGroup: 2,
        slidesPerColumn: 2,
      },
      992: {
        slidesPerView: 5,
        slidesPerGroup: 4,
        slidesPerColumn: 2,
      },
    },
    on: {
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.marketplace-shop-swiper': {
    slidesPerView: 3.25,
    slidesPerGroup: 3,
    slidesPerColumn: 1,
    spaceBetween: 30,
    slidesPerColumnFill: 'row',
    navigation: {
      nextEl: '.marketplace-shop-swiper-next',
      prevEl: '.marketplace-shop-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 12,
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        slidesPerGroup: 3,
        slidesPerColumn: 2,
      },
      992: {
        slidesPerView: 5,
        slidesPerGroup: 4,
        slidesPerColumn: 2,
      },
    },
    on: {
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.marketplace-areas-swiper': {
    slidesPerView: 2.25,
    slidesPerGroup: 2,
    slidesPerColumn: 1,
    spaceBetween: 30,
    slidesPerColumnFill: 'row',
    navigation: {
      nextEl: '.marketplace-areas-swiper-next',
      prevEl: '.marketplace-areas-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 12,
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        slidesPerGroup: 3,
        slidesPerColumn: 1,
      },
      992: {
        slidesPerView: 5,
        slidesPerGroup: 4,
        slidesPerColumn: 1,
      },
    },
    on: {
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.user-swiper': {
    slidesPerView: 2.25,
    slidesPerGroup: 1,
    spaceBetween: 16,
    watchOverflow: true,
    navigation: {
      nextEl: '.user-swiper-next',
      prevEl: '.user-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 6,
    },
    breakpoints: isMobile ? {
      768: {
        slidesPerView: 8,
        slidesPerGroup: 3,
        spaceBetween: 32,
      },
      992: {
        slidesPerView: 10,
        slidesPerGroup: 5,
        spaceBetween: 32,
      },
    } : {
      768: {
        slidesPerView: 4,
        slidesPerGroup: 3,
        spaceBetween: 32,
      },
      992: {
        slidesPerView: 6,
        slidesPerGroup: 5,
        spaceBetween: 32,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.userFullWidth-swiper': {
    slidesPerView: 4,
    spaceBetween: 16,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: true
    },
    speed: 5000,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 6,
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 32,
      },
      992: {
        slidesPerView: 8,
        spaceBetween: 50,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.ranking-swiper': {
    slidesPerView: 1.25,
    spaceBetween: 16,
    navigation: {
      nextEl: '.ranking-swiper-next',
      prevEl: '.ranking-swiper-prev',
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 6,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      880: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 16,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.auction-highlighted-swiper': {
    slidesPerView: 2,
    slidesPerGroup: 1,
    slidesPerColumn: 1,
    spaceBetween: 30,
    slidesPerColumnFill: 'row',
    observer: true,
    navigation: {
      nextEl: '.auction-highlighted-swiper-next',
      prevEl: '.auction-highlighted-swiper-prev',
    },
    keyboard: {
      enabled: true,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 30
    },
    breakpoints: {
      576: {
        slidesPerView: 3,
        slidesPerGroup: 2,
        slidesPerColumn: 1,
      },
      768: {
        slidesPerView: 4,
        slidesPerGroup: 3,
        slidesPerColumn: 2,
      },
      992: {
        slidesPerView: 6,
        slidesPerGroup: 5,
        slidesPerColumn: 2,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.auction-highlighted-no-keyboard-swiper': {
    slidesPerView: 2,
    slidesPerGroup: 1,
    slidesPerColumn: 1,
    spaceBetween: 30,
    slidesPerColumnFill: 'row',
    observer: true,
    navigation: {
      nextEl: '.auction-highlighted-swiper-next',
      prevEl: '.auction-highlighted-swiper-prev',
    },
    keyboard: {
      enabled: false,
    },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 30
    },
    breakpoints: {
      576: {
        slidesPerView: 3,
        slidesPerGroup: 2,
        slidesPerColumn: 1,
      },
      768: {
        slidesPerView: 4,
        slidesPerGroup: 3,
        slidesPerColumn: 2,
      },
      992: {
        slidesPerView: 6,
        slidesPerGroup: 5,
        slidesPerColumn: 2,
      },
    },
    on: {
      lazyImageReady: function(swiper, slideEl, imageEl) {
        if ('objectFit' in document.documentElement.style === false) {
          (slideEl.runtimeStyle || slideEl.style).background = "url(\"".concat(slideEl.src, "\") no-repeat 50%/").concat(slideEl.currentStyle ? slideEl.currentStyle['object-fit'] : slideEl.getAttribute('data-object-fit'))
          slideEl.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='".concat(slideEl.width, "' height='").concat(slideEl.height, "'%3E%3C/svg%3E")
        }
      },
      init: checkSwiperNavigation,     
      slideChange: checkSwiperNavigation
    }
  },
  '.mobilePopular-swiper': {
    slidesPerView: "auto",
    freeMode: true,
  }};

  let initializeSwipers = (e) => {
    requestAnimationFrame(() => {
      for (let selector in swiperConfigs) {
        document.querySelectorAll(selector.concat(':not(.swiper-container-initialized)')).forEach(el => {
          let distanceFromViewport = el.getBoundingClientRect().top - innerHeight
          if(distanceFromViewport < 200){
            new Swiper(el, swiperConfigs[selector]);
          }
        });
      }
    });
  }

  let initializeSwipersAfterReadyStateChange = (e) => {
    if ( document.readyState === "complete" || (e && e.target && e.target.readyState === "complete")) {
      setTimeout(() => {
        initializeSwipers();
      }, 100);
    }
  }

  document.addEventListener('scroll', initializeSwipers);
  document.addEventListener('readystatechange', initializeSwipersAfterReadyStateChange);
  document.addEventListener('turbolinks:load', () => setTimeout(() => {    initializeSwipers()  }, 100));  
  document.addEventListener('load', () => setTimeout(() => {
    initializeSwipers()
  }, 100));
  initializeSwipers();
})
