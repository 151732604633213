import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['fileInput', 'previewImage'];

  select() {
    this.fileInputTarget.click();
  }

  upload(e) {
    if (this.fileInputTarget.files.length < 1) {
      return false;
    }

    const data = new FormData();
    data.append(this.data.get('field-name'), this.fileInputTarget.files[0]);
    data.append('authenticity_token', document.getElementById('authenticity_token').value);

    $.ajax({
      url: this.data.get('upload-url'),
      type: 'post',
      data: data,
      contentType: false,
      processData: false,
      success: (response) => {
        this.previewImageTarget.src = response[this.data.get('response-field-name') || 'image_url'];
      },
    });
  }
}
