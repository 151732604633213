import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = []

  connect() {
    setTimeout(() => {
      const url = new URL(location.href);
      url.searchParams.delete('item_added');
      url.searchParams.delete('item_updated');
      url.searchParams.delete('item_deleted');
      location = url;
    }, this.data.get('refresh-time') || 4000);
  }
}
