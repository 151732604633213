import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['element', 'selector'];

  initialize() {
    this.selectorClass = this.data.get('selector-class') || 'selected';
    this.elementClass = this.data.get('element-class');

    var that = this;
    this.selectorTargets.forEach((selector) => {
      const element = document.getElementById(selector.getAttribute('data-select-element-id'));
      if (selector == that.selectorTargets[0]) {
        element.addEventListener("keydown", (event) => {
          if (event.key == "Tab" && !event.shiftKey) {
            document.querySelector(`[data-select-element-id=${event.target.id}]`).focus();
          }
        });
      } else {
        element.addEventListener("keydown", (event) => {
          if (event.key == "Tab") {
            document.querySelector(`[data-select-element-id=${event.target.id}]`).focus();
          }
        });
      }
    });
  }

  select(e) {
    this.selectorTargets.forEach((selector) => {
      const element = document.getElementById(selector.getAttribute('data-select-element-id'));

      if (e.currentTarget == selector) {
        selector.classList.add(this.selectorClass);
        element.classList.remove('d-none');
        element.focus();
      } else {
        selector.classList.remove(this.selectorClass);
        element.classList.add('d-none');
      }
    });

    if (this.data.has('remote-url')) {
      this.remoteUpdate();
    }
  }

  remoteUpdate() {
    const data = new FormData();
    this.elementTargets.forEach((e) => {
      data.append(e.name, e.value);
    });

    const url = this.data.get('remote-url');
    const csrfToken = document.querySelector('[name=\'csrf-token\']').content;
    fetch(url, {
      headers: {'X-CSRF-Token': csrfToken},
      method: 'PUT',
      body: data,
    });
  }
}
