import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', this.enterHandler.bind(this));
  }

  enterHandler(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  }
}
