import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.form.getElementsByTagName('input').forEach((input) => {
      input.addEventListener('invalid', (e) => {
        setTimeout(() => this.element.disabled = false, 0);
      });
    });
  }
  disable(event) {
    setTimeout(() => event.target.disabled = true, 0);
  }
}
