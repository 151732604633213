import {Controller} from 'stimulus';
import Dropzone from 'dropzone';

export default class extends Controller {
  static targets = ['dropZone', 'fileInput', 'fileInputDetach', 'noImageWrap', 'imageWrap', 'image'];

  connect() {
    const controller = this;
    // add visual drag information
    this.dropZoneTarget.addEventListener('dragover', (e) => controller.dragover(e));
    this.dropZoneTarget.addEventListener('dragenter', (e) => controller.dragover(e));
    this.dropZoneTarget.addEventListener('dragleave', (e) => controller.dragleave(e));
    this.dropZoneTarget.addEventListener('dragend', (e) => controller.dragleave(e));
    this.dropZoneTarget.addEventListener('drop', (e) => controller.drop(e));
    this.fileInputTarget.addEventListener('change', (e) => controller.preview());
  }

  dragover(e) {
    this.dropZoneTarget.classList.add('active');
    e.preventDefault();
    e.stopPropagation();
  }

  dragleave(e) {
    this.dropZoneTarget.classList.remove('active');
    e.preventDefault();
    e.stopPropagation();
  }

  drop(e) {
    const files = e.dataTransfer.files;
    if (files.length) {
      this.fileInputTarget.files = files;
      this.fileInputDetachTarget.value = false;
    }
    this.preview();

    e.preventDefault();
    e.stopPropagation();
  }

  edit(e) {
    this.fileInputTarget.click();
    e.stopPropagation();
  }

  delete(e) {
    const result = confirm(this.data.get('deleteConfirm'));
    if (result == true) {
      this.imageWrapTarget.classList.add('d-none');
      this.noImageWrapTarget.classList.remove('d-none');
      this.fileInputTarget.value = null;
      this.fileInputTarget.dispatchEvent(new Event('delete'));
      this.fileInputDetachTarget.value = true;
    }
    e.stopPropagation();
  }

  preview() {
    this.imageTarget.src = URL.createObjectURL(this.fileInputTarget.files[0]);
    this.imageWrapTarget.classList.remove('d-none');
    this.noImageWrapTarget.classList.add('d-none');

    const invalidIndicator = this.element.closest('.is-invalid');
    if (invalidIndicator) {
      invalidIndicator.classList.remove('is-invalid');
    }
  }
}
