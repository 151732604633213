import {Controller} from 'stimulus';

class FacetSearchController extends Controller {
  static targets = ['result', 'query', 'hits', 'took', 'link', 'reset_link']

  initialize() {
    this.resultTargets.forEach((element) => {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set(
          element.getAttribute('data-param-name'),
          element.getAttribute('data-param-value'),
      );
      const url = location.href.replace(location.search, '') + '?' + urlParams;
      element.href = url;
    });

    if (this.hasReset_linkTarget) {
      this.enableLink(this.reset_linkTarget);
    }
  }

  fullTextSearch(event) {
    const query = this.normalizedText(this.queryTarget.value);

    if (query == '') {
      this.resetFilter(event);
      return;
    }

    this.makeAllLinksInactive();

    this.filter(query, event,
        (normalizedText, query) => normalizedText.includes(query) );

    event.preventDefault();
  }

  filterByFirstLetter(event) {
    this.resetQueryAndSetActive(event.currentTarget);

    this.filter(event.currentTarget.innerHTML, event,
        (normalizedText, query) => normalizedText.startsWith(query) );

    event.preventDefault();
  }

  filterForFirstCharacterNotALetter(event) {
    this.resetQueryAndSetActive(event.currentTarget);

    this.filter(event.currentTarget.innerHTML, event,
        (normalizedText, query) => !normalizedText.match(/^[a-zA-Z]/));

    event.preventDefault();
  }

  resetFilter(event) {
    this.resetQueryAndSetActive(this.reset_linkTarget);

    this.filter(null, event,
        (normalizedText, query) => true);

    event.preventDefault();
  }

  filterClicked(event) {
    const url = new URL(window.location);
    url.searchParams.set(
        event.currentTarget.getAttribute('data-param-name'),
        event.currentTarget.getAttribute('data-param-value'),
    );
    url.searchParams.delete('page');
    event.currentTarget.href = url.toString();

    if (this.openNewTab(event)) {
      event.currentTarget.target = '_blank';
      event.currentTarget.rel = 'opener';
    }
    event.currentTarget.click();
  }

  private

  filter(query, event, func) {
    let hits = 0;
    const startTime = new Date();

    this.resultTargets.forEach((element) => {
      if (func(this.normalizedText(element.querySelector('.text').innerHTML), query)) {
        element.parentNode.style.display='block';
        hits += 1;
      } else {
        element.parentNode.style.display='none';
      }
    });
    this.updateStats(startTime, hits);

    if (query && query.trim() != '') {
      this.disableLink(this.reset_linkTarget);
    } else {
      this.enableLink(this.reset_linkTarget);
    }
  }

  normalizedText(text) {
    return this.removeAccents(text.toLowerCase());
  }

  resetQuery() {
    this.queryTarget.value = '';
  }

  updateStats(startTime, hits) {
    this.hitsTarget.innerHTML = hits;
    if (this.hasTookTarget) {
      this.tookTarget.innerHTML = new Date() - startTime;
    }
  }

  makeAllLinksInactive() {
    this.disableLink(this.reset_linkTarget);

    this.linkTargets.forEach((element) => {
      this.disableLink(element);
    });
  }

  resetQueryAndSetActive(element) {
    this.resetQuery();
    this.makeAllLinksInactive();
    this.enableLink(element);
  }

  enableLink(element) {
    element.classList.add('active');
  }

  disableLink(element) {
    element.classList.remove('active');
  }

  removeAccents(str) {
    const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    str = str.split('');
    str.forEach((letter, index) => {
      const i = accents.indexOf(letter);
      if (i != -1) {
        str[index] = accentsOut[i];
      }
    });
    return str.join('');
  }

  openNewTab(event) {
    return event.ctrlKey ||
      event.shiftKey ||
      event.metaKey || // apple
      (event.button && event.button == 1); // middle click, >IE9 + everyone else
  }
}

export default FacetSearchController;
