import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['days', 'hours', 'minutes', 'seconds'];

  initialize() {
    this.countdownTimer();
  }

  countdownTimer() {
    this.interval = this.interval || setInterval(this.countdownTimer.bind(this), 1000);
    this.date = this.date || this.data.get('endtime');
    const endTime = new Date(this.date);
    const difference = +endTime - +new Date();

    if (difference > 0) {
      const parts = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      if (parts.days > 0) {
        this.daysTarget.querySelector('.value').innerHTML = parts.days;
      } else {
        this.daysTarget.classList.add('d-none');
      }
      this.hoursTarget.querySelector('.value').innerHTML = parts.hours;
      this.minutesTarget.querySelector('.value').innerHTML = parts.minutes;
      this.secondsTarget.querySelector('.value').innerHTML = parts.seconds;
    } else {
      clearInterval(this.interval);
      if (Element.prototype.closest) { // not supported on old browsers
        // TODO show a friendlier 'closed' message
        const card = this.element.closest('.card');
        card.parentNode?.removeChild(card);
      }
    }
  }
}
