import {Controller} from 'stimulus';

const SINGULAR = 0;
const PLURAL = 1;

const TRANSLATIONS = {
  nl: {days: ['dag', 'dagen'],
    hours: ['uur', 'uur'],
    minutes: ['minuut', 'minuten'],
    seconds: ['seconde', 'seconden']},
  en: {days: ['day', 'days'],
    hours: ['hour', 'hours'],
    minutes: ['minute', 'minutes'],
    seconds: ['second', 'seconds']},
  de: {days: ['Tag', 'Tagen'],
    hours: ['Stunde', 'Stunden'],
    minutes: ['Minute', 'Minuten'],
    seconds: ['Sekunde', 'Sekunden']},
  fr: {days: ['jour', 'jours'],
    hours: ['heure', 'heures'],
    minutes: ['minute', 'minuten'],
    seconds: ['seconde', 'seconden']},
};


export default class extends Controller {
  static targets = ['in_words'];

  initialize() {
    const createdAt = new Date(this.data.get('created_at'));
    const difference = +new Date() - +createdAt;
    this.inWords(difference, this.data.get('locale'));
  }

  inWords(difference, locale) {
    const parts = this.splitIntoParts(difference);

    if (parts.days > 0) {
      this.in_wordsTarget.innerHTML = this.localize(locale, 'days', parts.days);
      return;
    }

    if (parts.hours > 0) {
      this.in_wordsTarget.innerHTML = this.localize(locale, 'hours', parts.hours);
      return;
    }

    if (parts.minutes > 0) {
      this.in_wordsTarget.innerHTML = this.localize(locale, 'minutes', parts.minutes);
      return;
    }

    if (parts.seconds < 0) {
      this.in_wordsTarget.innerHTML = this.localize(locale, 'seconds', 0);
      return;
    }

    this.in_wordsTarget.innerHTML = this.localize(locale, 'seconds', parts.seconds);
  }

  splitIntoParts(difference) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  localize(locale, key, count) {
    return `${count} ${TRANSLATIONS[locale][key][count > 1 ? PLURAL : SINGULAR]}`;
  }
}


