import {Controller} from 'stimulus';

const BE_ID = 229;
const NL_ID = 346;

export default class extends Controller {
  static targets = ['zipcode', 'number', 'extension', 'city', 'citySelect', 'address', 'country', 'warning'];

  fetch() {
    if (this.zipcodeTarget.value != '') {
      let url = 'https://api.pro6pp.nl/v1/autocomplete?';

      const zipcode = this.zipcodeTarget.value.replace(/\s+/g, '');

      if (this.countryTarget.value == NL_ID && this.numberTarget.value.length >= 1) {
        url += `auth_key=3j6uuCkbbkSTyZRr&nl_sixpp=${escape(zipcode)}
                &streetnumber=${escape(this.numberTarget.value)}&format=json`;
        if (this.hasExtensionTarget && this.extensionTarget.value != '') {
          url += `extension=${escape(this.extensionTarget.value)}`;
        }

        fetch( url,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            },
        ).then((response) => response.json())
            .then((data) => {
              if (data. status == 'error') {
                this.cityTarget.readOnly = false;
                this.addressTarget.readOnly = false;
                this.warningTarget.hidden = false;
              } else {
                this.addressTarget.value = data.results[0].street;
                this.addressTarget.readOnly = true;
                this.cityTarget.value = data.results[0].city;
                this.cityTarget.readOnly = true;
                this.warningTarget.hidden = true;
              }
            });
      }
      if (this.countryTarget.value == BE_ID) {
        url += `auth_key=3j6uuCkbbkSTyZRr&be_fourpp=${escape(zipcode)}&format=json`;

        fetch( url,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            },
        ).then((response) => response.json())
            .then((data) => {
              this.populateDropdown(data.results);
            });
      }
    }
  }

  populateDropdown(results) {
    if (results.length == 1) {
      this.cityTarget.value = results[0].city;
    } else {
      // remove all elements first
      this.cityTarget.value = '';
      this.citySelectTarget.innerHTML = '';

      // repopulate
      for (let i = 0; i < results.length; i++) {
        const opt = results[i];
        this.citySelectTarget.innerHTML +=
          '<option value="' + opt.city + '">' + opt.city + '</option>';
      }
    }
  }
}
