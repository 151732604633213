import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'modal', 'disable']

  toggle() {
    $(this.modalTarget.value).modal({show: this.checkboxTarget.checked});
    this.disableTarget.disabled = !this.checkboxTarget.checked;
  }
}
