import {Controller} from 'stimulus';
export default class extends Controller {
  static targets = ['field', 'output']
  static classes = ['overLimit']
  static values = {
    maxChars: Number,
    submitSelector: String,
  }

  connect() {
    this.change();
    console.debug(this.submitSelectorValue);
  }

  change() {
    const length = this.fieldTarget.value.length;
    this.outputTarget.textContent = `${length} / ${this.maxCharsValue}`;

    if (length > this.maxCharsValue) {
      this.outputTarget.classList.add(this.overLimitClass);
      this.disableSubmit();
    } else {
      this.outputTarget.classList.remove(this.overLimitClass);
      this.enableSubmit();
    }
  }

  disableSubmit() {
    if (this.hasSubmitSelectorValue) {
      document.querySelector(this.submitSelectorValue).disabled = true;
    }
  }

  enableSubmit() {
    if (this.hasSubmitSelectorValue) {
      document.querySelector(this.submitSelectorValue).disabled = false;
    }
  }
}
