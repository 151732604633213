import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['count', 'selectAll', 'selectedIndicator', 'selectedField', 'checkbox'];

  connect() {
    this.loadSelected();
    this.setCount();
  }

  toggleAll(e) {
    if (e.target.checked) {
      this.checkAll(e);
    } else {
      this.checkNone(e);
    }
  }

  checkAll(e) {
    this.setAllCheckboxes(true);
    this.setCount();
    e.preventDefault();
  }

  checkNone(e) {
    this.setAllCheckboxes(false);
    this.setCount();
    e.preventDefault();
  }

  onToggle(e) {
    const that = this;

    if (this.hasSelectedFieldTarget) {
      this.selectedFieldTargets.forEach((selectedField) => {
        selectedField.value = new Array(...this.selectedCheckboxes).map((c) => c.value).join(',');
      });
    }

    // Update selection on server
    if (!(e.detail && e.detail.bulk) &&
          this.data.has('toggleUrl') &&
          this.data.has('toggleParamName')) {
      const params = {};
      params[this.data.get('toggleParamName')] = e.target.value;

      if (this.data.has('authenticityTokenId')) {
        params['authenticity_token'] = $('#'+this.data.get('authenticityTokenId')).val();
      }

      $.ajax({url: this.data.get('toggleUrl'),
        type: e.target.checked ? 'post' : 'delete',
        data: params}).done(() => {
        that.setCount();

        if (this.hasSelectAllTarget) {
          this.selectAllTarget.checked = this.checkboxes.length ==
                                         this.selectedCheckboxes.length;
        }
      });
    } else {
      this.setCount();
    }

    e.preventDefault();
  }

  setAllCheckboxes(checked) {
    const values = [];
    this.checkboxes.forEach((checkbox) => {
      if (!checkbox.disabled && checkbox.checked != checked) {
        checkbox.checked = checked;
        checkbox.dispatchEvent(new CustomEvent('change', {detail: {bulk: true}}));
        values.push(checkbox.value);
      }
    });

    if (this.hasSelectedFieldTarget) {
      this.selectedFieldTargets.forEach((selectedField) => {
        selectedField.value = new Array(...this.selectedCheckboxes).map((c) => c.value).join(',');
      });
    }

    // Update selection on server
    if (this.data.has('toggleUrl') &&
        this.data.has('toggleParamName')) {
      const params = {};
      params[this.data.get('toggleParamName')] = values.join(',');
      $.ajax({url: this.data.get('toggleUrl'),
        type: checked ? 'post' : 'delete',
        data: params}).done(() => this.setCount());
    }
  }

  setCount() {
    this.getCount((count) => {
      if (this.hasCountTarget) {
        this.countTarget.innerHTML = `${count}`;
      }
      if (this.hasSelectedIndicatorTarget) {
        if (count > 0) {
          this.selectedIndicatorTarget.classList.add('active');
        } else {
          this.selectedIndicatorTarget.classList.remove('active');
        }
      }
    });
  }

  getCount(callback) {
    if (this.data.has('loadSelectedUrl')) {
      $.getJSON(this.data.get('loadSelectedUrl'), function(itemIds) {
        callback(itemIds.length);
      });
    } else {
      callback(this.selectedCheckboxes.length);
    }
  }

  loadSelected() {
    const that = this;
    if (this.data.has('loadSelectedUrl')) {
      $.getJSON(this.data.get('loadSelectedUrl'), function(itemIds) {
        let checkedCount = 0;
        that.checkboxes.forEach((checkbox) => {
          if (itemIds.includes(parseInt(checkbox.value))) {
            checkbox.checked = true;
            checkedCount += 1;
            checkbox.dispatchEvent(new CustomEvent('change', {detail: {bulk: true}}));
          }
        });

        // update selectAll checkbox based on selected count
        if (that.hasSelectAllTarget && that.checkboxes.length == checkedCount) {
          that.selectAllTarget.checked = true;
        }
      });
    }
  }

  get selectedCheckboxes() {
    return this.checkboxes.filter((c) => c.checked);
  }

  get checkboxes() {
    if (this.hasCheckboxTarget) {
      return new Array(...this.checkboxTargets);
    } else {
      return new Array(...this.element.querySelectorAll('.card-select input[type=checkbox]'));
    }
  }
}
