import {Controller} from 'stimulus';

export default class extends Controller {
  static values = {
    id: Number,
    quantity: Number,
  }

  updateModal() {
    const url = '/shop/cancel_order_line/';
    document.getElementById('new_order_line').action = url + this.idValue;

    console.log(this.idValue);
    if (this.quantityValue == 1) {
      document.getElementById('order-cancel-target-one').hidden=false;
      document.getElementById('order-cancel-target-multiple').hidden=true;
    } else {
      document.getElementById('order-cancel-target-quantity').textContent = this.quantityValue;
      document.getElementById('order-cancel-target-one').hidden=true;
      document.getElementById('order-cancel-target-multiple').hidden=false;
    }
  }
}
