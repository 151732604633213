import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['select']

  connect() {
    const selection = this.getGradeValue();
    if (selection) {
      this.selectTarget.value = selection;
    }
  }

  storageKey() {
    return `${this.data.get('collection-type')}_type_${this.data.get('type-id')}_grade_value`;
  }

  getGradeValue() {
    return this.getCookie(this.storageKey());
  }

  saveGradeValue(e) {
    document.cookie = `${this.storageKey()}=${e.target.value}; path=/ ;expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  }

  getCookie(name) {
    if (document.cookie.length > 0) {
      let start = document.cookie.indexOf(name + '=');
      if (start != -1) {
        start = start + name.length + 1;
        let end = document.cookie.indexOf(';', start);
        if (end == -1) {
          end = document.cookie.length;
        }
        return unescape(document.cookie.substring(start, end));
      }
    }
    return '';
  }
}
