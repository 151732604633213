import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['fromOrderAmount', 'toOrderAmount', 'addLink', 'list'];
  static values = {url: String}

  add(element) {
    let lastToOrderAmount;
    let lastIndex;

    if (this.hasToOrderAmountTarget) {
      const lastTo = this.toOrderAmountTargets[this.toOrderAmountTargets.length - 1];
      lastToOrderAmount = lastTo.value;
      lastIndex = parseInt(lastTo.dataset.index)+1;
    } else {
      lastIndex = 1;
    }

    fetch(this.urlValue + '?fromOrderAmount=' + lastToOrderAmount + '&index=' + lastIndex)
        .then((response) => response.text())
        .then((html) => this.listTarget.insertAdjacentHTML('beforeend', html));
  }

  sync(event) {
    const source = event.target;
    let destination;
    const index = parseInt(source.dataset.index);
    let indexToUpdate;
    let destinationTargets;
    let from;
    let to;

    if (source.dataset.shippingRatesTarget == 'fromOrderAmount') {
      from = source;
      destinationTargets = this.toOrderAmountTargets;
      indexToUpdate = index - 1;
    } else {
      to = source;
      destinationTargets = this.fromOrderAmountTargets;
      indexToUpdate = index + 1;
    }

    for (const destinationTarget of destinationTargets) {
      if (parseInt(destinationTarget.dataset.index) == index) {
        destination = destinationTarget;
      }
    }

    for (const destinationTarget of destinationTargets) {
      if (parseInt(destinationTarget.dataset.index) == indexToUpdate) {
        destinationTarget.value = source.value;
      }
    }

    for (const toOrderAmountTarget of this.toOrderAmountTargets) {
      toOrderAmountTarget.classList.remove('is-invalid');
    }

    for (const toOrderAmountTarget of this.toOrderAmountTargets) {
      const fromOrderAmountTarget = document.getElementById(toOrderAmountTarget.id.replace('to_order_amount', 'from_order_amount'));

      if (fromOrderAmountTarget.value && toOrderAmountTarget.value) {
        if (Number(fromOrderAmountTarget.value.replace(',', '.')) >= Number(toOrderAmountTarget.value.replace(',', '.'))) {
          toOrderAmountTarget.classList.add('is-invalid');
          toOrderAmountTarget.focus();
          return;
        }
      }
    }
  }
}
