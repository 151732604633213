import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['modal'];

  select(e) {
    document.getElementById(this.data.get('input-id')).value =
      e.currentTarget.getAttribute('data-image-url');
    document.getElementById(this.data.get('image-id')).src =
      e.currentTarget.getAttribute('data-image-url');

    if (this.hasModalTarget) {
      $(this.modalTarget).modal('hide');
    }

    e.preventDefault();
  }
}
