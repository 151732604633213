import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    const $activeItem = $(this.element).find('.item.active');
    $activeItem.tooltip('show');
    let tooltipTimeout;
    $(this.element).find('.item:not(.active').on('show.bs.tooltip', function() {
      clearTimeout(tooltipTimeout);
      $activeItem.tooltip('hide');
    });
    $(this.element).find('.item:not(.active').on('hide.bs.tooltip', function() {
      tooltipTimeout = setTimeout(() => {
        $activeItem.tooltip('show');
      }, 400);
    });
  }
}
