import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'link', 'focus'];

  connect() {
    if (this.data.get('hide-chart-on-load') == 'true') {
      setTimeout(this.hide.bind(this), 1000);
    }
  }

  unhide(e) {
    this.contentTarget.hidden=false;
    this.linkTarget.hidden=true;
    if (this.hasFocusTarget) {
      this.focusTarget.focus();
    }

    if (e && this.data.get('active-btn-class') != null) {
      parent = e.target.parentElement;
      parent.children.forEach((child) => {
        if (child == e.target) {
          child.classList.remove(this.data.get('inactive-btn-class'));
          child.classList.add(this.data.get('active-btn-class'));
        } else {
          child.classList.remove(this.data.get('active-btn-class'));
          child.classList.add(this.data.get('inactive-btn-class'));
        }
      });
    }
  }

  hide(e) {
    this.contentTarget.hidden=true;
    this.linkTarget.hidden=false;
    if (this.hasFocusTarget) {
      this.focusTarget.focus();
    }

    if (e && this.data.get('active-btn-class') != null) {
      parent = e.target.parentElement;
      parent.children.forEach((child) => {
        if (child == e.target) {
          child.classList.remove(this.data.get('inactive-btn-class'));
          child.classList.add(this.data.get('active-btn-class'));
        } else {
          child.classList.remove(this.data.get('active-btn-class'));
          child.classList.add(this.data.get('inactive-btn-class'));
        }
      });
    }
  }
}
