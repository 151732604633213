import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['source', 'hideCollection', 'hideWishList'];

  collectionTypeBodyClass = {
    'C': 'my-collection',
    'W': 'my-searchlist',
    'S': 'my-shop',
  };

  initialize() {
    this.toggle();
  }

  toggle() {
    const checkedRadio = this.sourceTargets.find((e) => {
      return e.checked;
    });
    const checkedValue = checkedRadio && checkedRadio.value;

    this.hideCollectionTargets.forEach((e) => {
      e.style.display = '';
    });
    this.hideWishListTargets.forEach((e) => {
      e.style.display = '';
    });

    switch (checkedValue) {
      case 'C':
        this.hideCollectionTargets.forEach((e) => {
          e.style.setProperty('display', 'none', 'important');
        });
        break;
      case 'W':
        this.hideWishListTargets.forEach((e) => {
          e.style.setProperty('display', 'none', 'important');
        });
        break;
      case 'S':
        break;
    }

    this.removeBodyClasses();
    document.body.classList.add(this.collectionTypeBodyClass[checkedValue]);
  }

  removeBodyClasses() {
    document.body.classList.remove(...Object.values(this.collectionTypeBodyClass));
  }
};
