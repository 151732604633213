import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['inner']
  BREAKPOINT = 992;

  previousWidth;
  resizeTimeout;

  connect() {
    this.doWindowResize = this.doWindowResize.bind(this); // to set the right scope
    this.previousWidth = $(window).width();

    this.checkBadgeResize();
    window.addEventListener('resize', this.doWindowResize, false);
  }

  disconnect() {
    window.removeEventListener('resize', this.doWindowResize, false);
  }

  checkBadgeResize() {
    if ($(window).width() >= this.BREAKPOINT) {
      const newWidth = this.innerTarget.offsetWidth + 4 + parseFloat($(this.element).css('padding-left') || '0') + parseFloat($(this.element).css('padding-right') || '0');
      this.element.style.width = `${newWidth}px`;
    } else {
      this.element.style.width = ``;
    }
    this.element.classList.add('resized');
  }

  doWindowResize() {
    const currentWidth = $(window).width();
    if (
      ((currentWidth < this.BREAKPOINT && this.previousWidth > this.BREAKPOINT) ||
      (currentWidth > this.BREAKPOINT && this.previousWidth < this.BREAKPOINT)) &&
      this.element.classList.contains('resized')
    ) {
      this.element.classList.remove('resized');
    }
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      const currentResizeWidth = $(window).width();
      if (
        (currentResizeWidth < this.BREAKPOINT && this.previousWidth > this.BREAKPOINT) ||
        (currentResizeWidth > this.BREAKPOINT && this.previousWidth < this.BREAKPOINT)
      ) {
        this.checkBadgeResize();
      }
      this.previousWidth = currentResizeWidth;
    }, 200);
  }
}
