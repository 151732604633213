import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'focus'];

  toggle(e) {
    this.contentTarget.classList.toggle(this.data.get('class'));
    if (this.hasFocusTarget && $(this.contentTarget).is(':visible')) {
      this.focusTarget.focus();
      this.focusTarget.scrollIntoView({'block': 'center'});
    }

    if (this.contentTarget.classList.contains(this.data.get('animation'))) {
      setTimeout(() => {
        this.contentTarget.classList.remove(this.data.get('animation'));
      }, 250);
    } else {
      this.contentTarget.classList.add(this.data.get('animation'));
    }
  }
}
