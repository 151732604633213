import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['status', 'form'];

  modal(e) {
    const checkbox = e.target;
    const status = this.statusTarget;
    if (checkbox.checked) {
      const modalName = checkbox.dataset.modal;
      checkbox.disabled = true;
      const oldStatus = this.statusTarget.value;
      this.statusTarget.value = checkbox.value;
      $(modalName).modal('toggle');

      $(modalName).off('hide');
      $(modalName).on('hide.bs.modal', function(e) {
        checkbox.checked = false;
        checkbox.disabled = false;
        status.value = oldStatus;
      });
    }
  }

  submit(e) {
    this.formTarget.submit();
  }
}
