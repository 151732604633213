import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['navbarContent'];

  connect() {
    $(document).click((event) => {
      const parentElement = event.target;
      if (parentElement !== this.navbarContentTarget &&
          $(parentElement).parents('#navbarContent').length === 0) {
        this.collapseMenu();
      }
    });

    this.lastScrollTop = 0;
    $(window).on('scroll touchmove', () => {
      this.collapseMenu();

      const scrollTop = $(window).scrollTop();
      if (scrollTop < 300 || scrollTop < this.lastScrollTop) {
        $(document.body).removeClass('mobileSlideUp').addClass('mobileSlideDown');
      } else {
        $(document.body).removeClass('mobileSlideDown').addClass('mobileSlideUp');
        $('#cartDropdown').dropdown('hide');
        $('#userDropdown').dropdown('hide');
      }
      this.lastScrollTop = scrollTop;
    });
  }

  collapseMenu() {
    if (this.navbarContentTarget && this.navbarContentTarget.classList.contains('show')) {
      $(this.navbarContentTarget).collapse('hide');
    }
  }
}
