import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [];

  initialize() {
    this.on_url = this.data.get('on');
    this.off_url = this.data.get('off');
  }

  toggle() {
    if (this.element.checked) {
      window.location = this.on_url;
    } else {
      window.location = this.off_url;
    }
  }
}
