// Limits the file types that can be uploaded in ActionText
// https://blog.saeloun.com/2019/11/12/attachments-in-action-text-rails-6.html

window.addEventListener("trix-file-accept", function(event) {  
  const acceptedTypes = ['image/jpeg', 'image/png']  
  if (!acceptedTypes.includes(event.file.type)) {    
    event.preventDefault()    
    alert("Only jpeg or png files can be uploaded")  
  }

  const maxFileSize = 10 * 1024 * 1024 // 5 MB
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Attachment size must be less than 5 MB")
  }
})
