import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['field'];

  initialize() {
    this.min = this.data.get('min') || 1;
    this.max = this.data.get('max');
  }

  validate() {
    const value = +this.fieldTarget.value;

    if (isNaN(value) || value < this.min ) {
      this.fieldTarget.value = this.min;
    } else if (value > this.max) {
      this.fieldTarget.value = this.max;
    }
  }
}
