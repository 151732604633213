import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['hidden', 'collapseLink', 'expandLink'];

  expand(event) {
    this.hiddenTargets.forEach((el) => el.classList.remove('d-none'));
    this.expandLinkTarget.classList.add('d-none');
    this.collapseLinkTarget.classList.remove('d-none');
    event.preventDefault();
  }
  collapse(event) {
    this.hiddenTargets.forEach((el) => el.classList.add('d-none'));
    this.expandLinkTarget.classList.remove('d-none');
    this.collapseLinkTarget.classList.add('d-none');
    event.preventDefault();
  }
}
