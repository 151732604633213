import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['scroller', 'toggler']
  connect() {
    $(document).on('shown.bs.dropdown', () => {
      this.toggleClass();
    });
    if (this.element.classList.contains('modal-dialog')) {
      $(this.element).parents('.modal:first').on('shown.bs.modal', (e) => {
        this.toggleClass();
      });
    }
    if (this.element.classList.contains('modal')) {
      $(this.element).on('shown.bs.modal', (e) => {
        this.toggleClass();
      });
    }
    if (this.data.get('init-toggle')) {
      this.toggleClass();
    }
    this.scrollerTarget.addEventListener('scroll', () => this.toggleClass(), {passive: true});
  }

  toggleClass() {
    this.togglerTarget.classList.toggle(
        'at-bottom',
        (
          this.scrollerTarget.clientHeight + this.scrollerTarget.scrollTop
        ) >= (this.scrollerTarget.scrollHeight - 10),
    );
  }
}
