import {Controller} from 'stimulus';
import Swiper from 'swiper';

export default class extends Controller {
  static targets = ['thumbnails', 'swiper']
  connect() {
    let openDate = new Date();
    this.swiper = new Swiper(this.swiperTarget, {
      lazy: true,
      zoom: true,
      watchOverflow: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
    });
    this.swiper.on('slideChange', ()=> this.toggleThumbnail());
    this.swiper.on('click', (swiper, event)=> {
      if ( +openDate < (+new Date() - 250) ) {
        this.slideClick(event);
      }
    });
    this.swiper.on('zoomChange', (scale)=> this.updateLayout(scale));
    this.element.addEventListener('openWith', (e)=>{
      openDate = new Date();
      this.swiper.update();
      this.showIndex(e.detail, 0);
    });
    this.element.addEventListener('lightboxHide', (e)=>{
      this.swiper.zoom.out();
    });
    window.addEventListener('load', () => {
      this.swiper.update();
    });
  }

  showThumb(e) {
    this.showIndex(e.target.dataset.index);
  }

  showIndex(index, speed) {
    this.swiper.slideToLoop(parseInt(index), speed);
  }

  slideClick(e) {
    if (this.data.has('lightbox') && e.target.tagName === 'IMG') {
      this.swiper.zoom.toggle(e);
    }
  }

  updateLayout(scale) {
    this.swiperTarget.classList.toggle('large', this.hasSwiperTarget && scale > 1);
  }

  toggleThumbnail() {
    if (this.hasThumbnailsTarget) {
      this.thumbnailsTarget.querySelector('.active')?.classList.remove('active');
      this.thumbnailsTarget.children[this.swiper.realIndex].classList.add('active');
    }
  }
}
