import {Controller} from 'stimulus';
import {MobileAppHelper} from '../helpers/mobile_app_helper';

export default class extends Controller {
  connect() {
    const dataString = this.data.get('data');
    if (dataString) {
      try {
        const data = JSON.parse(dataString);

        if (data.debug) {
          MobileAppHelper.debug();
        }

        MobileAppHelper.updateLoginStatus(data.avatar_url);
        MobileAppHelper.updateShoppingCartCount(data.shopping_cart_quantity || 0);
        MobileAppHelper.updateRouting(data);

        window.addEventListener('request_route', (event) => {
          MobileAppHelper.updateRouting(data);
        });
      } catch (e) {
        console.error('Error parsing mobile app data', e);
      }
    }
  }
}
