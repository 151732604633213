import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['cancel', 'tnt', 'provider', 'dropdown', 'tntPresent', 'default', 'invoice'];

  update() {
    if (this.dropdownTarget.value == '25') {
      this.showCancelled();
    } else if (this.dropdownTarget.value == '17') {
      this.showShipping();
    } else if (this.dropdownTarget.value == '31') {
      this.showInvoiceSent();
    } else {
      this.showDefault();
    }
  }

  showCancelled() {
    this.defaultTarget.hidden = false;
    this.invoiceTarget.hidden = true;
    this.cancelTarget.hidden = false;
    this.tntTarget.hidden = true;
    this.providerTarget.hidden = true;
    this.tntPresentTarget.value = false;
  }

  showShipping() {
    this.defaultTarget.hidden = false;
    this.invoiceTarget.hidden = true;
    this.cancelTarget.hidden = true;
    this.tntTarget.hidden = false;
    this.providerTarget.hidden = false;
    this.tntPresentTarget.value = true;
  }

  showInvoiceSent() {
    this.defaultTarget.hidden = true;
    this.invoiceTarget.hidden = false;
  }

  showDefault() {
    this.invoiceTarget.hidden = true;
    this.defaultTarget.hidden = false;
    this.cancelTarget.hidden = true;
    this.tntTarget.hidden = true;
    this.providerTarget.hidden = true;
    this.tntPresentTarget.value = false;
  }
}
