import {Controller} from 'stimulus';

let tokenRequest;
export default class extends Controller {
  static targets = ['tokenField']
  connect() {
    this.fetchToken((token)=> {
      this.tokenFieldTarget.value = token;
    });
  }

  fetchToken(callback) {
    if (tokenRequest) {
      return tokenRequest.then(callback);
    }
    tokenRequest = fetch('/csrf_token')
        .then((res) => res.text());

    tokenRequest.then(callback);
  }
}
