import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['source', 'target', 'button', 'detach', 'original'];

  changeFile() {
    const filereader = new FileReader();
    const target = this.targetTarget;

    filereader.addEventListener('load', function() {
      // convert image file to base64 string
      target.src = filereader.result;
    }, false);

    filereader.readAsDataURL(this.sourceTarget.files[0]);
    this.detachTarget.value=false;
    if (this.hasButtonTarget) {
      this.buttonTarget.hidden=false;
    }
  }

  removeFile() {
    this.targetTarget.src=this.originalTarget.value;
    if (this.hasButtonTarget) {
      this.buttonTarget.hidden=true;
    }
    this.detachTarget.value=true;
  }
}
