import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    $(this.element).popover({trigger: 'focus'});
  }
  click(e) {
    e.preventDefault();
  }
}
