import {Controller} from 'stimulus';

export default class extends Controller {
  static values = {
    status: Number,
  }

  updateStatus() {
    document.getElementById('order_status_id').value = this.statusValue;
    document.getElementById('order_status_id').dispatchEvent(new Event('change'));
  }
}
