import {Controller} from 'stimulus';

export default class extends Controller {
  translate() {
    window.location.href = this.urlWithoutParam('original');
  }

  original() {
    window.location.href = this.urlWithParam('original', true);
  }

  urlWithoutParam(parameter) {
    let originalUrl = window.location.href.split('?')[0];
    originalUrl += '?';
    const params = location.search.substr(1).split('&');
    for (let index = 0; index < params.length; index++) {
      const param = params[index].split('=');

      if (param[0] != parameter) {
        originalUrl += params[index];
      }
    }

    return originalUrl;
  }

  urlWithParam(parameter, value) {
    const params = new URLSearchParams(window.location.search);
    params.append(parameter, value);
    return window.location.href.split('?')[0] += '?' + params;
  }
}
