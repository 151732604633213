import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'button', 'results'];

  connect() {
    this.toggleButton();
    this.fieldTarget.addEventListener('keyup', this.toggleButton.bind(this));
  }

  clear() {
    this.fieldTarget.value = '';
    this.toggleButton();
    if (this.hasResultsTarget) {
      this.resultsTarget.innerHTML = '';
    }
  }

  toggleButton() {
    if (this.fieldTarget.value.trim().length > 0) {
      this.buttonTarget.classList.remove('d-none');
    } else {
      this.buttonTarget.classList.add('d-none');
    }
  }
}
