import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'errorMessage'];

  connect() {
    // Get Stripe.js client
    const stripeOptions = {};
    if (this.data.get('stripe-account-id')) {
      stripeOptions.stripeAccount = this.data.get('stripe-account-id');
    }
    const stripe = Stripe(this.data.get('pk'), stripeOptions);

    // Set up Stripe.js and Elements and add to form
    const appearance = { /* appearance */ };
    const options = {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: true
      },
      fields: {
        billingDetails: {
          name: 'never',
          email: 'never'
        }
      }
    };
    const billingName = this.data.get('billing-name');
    const billingEmail = this.data.get('billing-email');
    const clientSecret = this.data.get('client-secret');
    const elements = stripe.elements({ clientSecret, appearance });
    const paymentElement = elements.create('payment', options);
    paymentElement.mount(this.data.get('container-selector'));

    // Setup 'submit' handler
    this.formTarget.addEventListener('submit', async (event) => {
      event.preventDefault();

      const {error} = await stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: this.data.get('return-url'),
          payment_method_data: {
            billing_details: {
              name: billingName,
              email: billingEmail,
            }
          }
        },
      });

      if (error) {
        // Show error for immediate problems (e.g. payment details incomplete)
        this.errorMessageTarget.textContent = error.message;
        this.errorMessageTarget.classList.remove('d-none');
      } else {
        // Customer will be redirected to 'return_url'
      }
    });
  }
}
