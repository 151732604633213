import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['toggable'];

  toggle(e) {
    console.debug('toggle');
    $(this.toggableTarget).dropdown('toggle');
  }

  select(e) {
    window.location = e.target.href;
    e.preventDefault();
  }
}
