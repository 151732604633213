import {Controller} from 'stimulus';
import Tribute from 'tributejs';
import Trix from 'trix';

let topic;

export default class extends Controller {
  static targets = ['field'];

  connect() {
    this.editor = this.fieldTarget.editor;
    topic = JSON.parse(this.fieldTarget.dataset.info);
    this.initializeTribute();

    window.addEventListener('show-mention', (e) => {
      this.tribute.showMenuForCollection(this.fieldTarget);
    });
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget);
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: false,
      lookup: 'name',
      containerClass: 'tribute-container',
      values: this.fetchUsers,
      noMatchTemplate: function() {
        return '<span style:"visibility: hidden;"></span>';
      },
    });
    this.tribute.attach(this.fieldTarget);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.fieldTarget.addEventListener('tribute-replaced', this.replaced);
  }

  fetchUsers(text, callback) {
    let locale = 'nl';
    const host = window.location.hostname;

    if (host.endsWith('.com')) {
      locale = 'en';
    } else if (host.endsWith('.fr')) {
      locale = 'fr';
    } else if (host.endsWith('.de')) {
      locale = 'de';
    }
    fetch('/' + locale + '/community/mentions.json?query=' + text + '&topic=' + topic)
        .then((response) => response.json())
        .then((users) => callback(users))
        .catch((errors) => callback([]));
  }

  replaced(event) {
    const mention = event.detail.item.original;
    const attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
      caption: '@' + mention.name,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(' ');
  }

  _pasteHtml(html, startPos, endPos) {
    const position = this.editor.getPosition();
    this.editor.setSelectedRange([position - (endPos - startPos), position]);
    this.editor.deleteInDirection('backward');
  }
}
