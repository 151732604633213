import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['selected'];

  select() {
    if (this.element.value != '') {
      window.location = this.element.value;
    }
  }
}
