import {Controller} from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
  }

  fetch(e) {
    Rails.ajax({
      url: this.data.get('url'),
      type: 'get',
      data: new URLSearchParams({'email': e.target.value}).toString(),
    });
    e.preventDefault();
  }
}
